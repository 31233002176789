import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { useStepsForm } from "sunflower-antd";

import step1 from "../../../images/step1.svg";
import step2 from "../../../images/step2.svg";
import step3 from "../../../images/step3.svg";
import previewImg from "../../../images/upload-banner.png";
import sampleImg from "../../../images/banner.jpg";
import AsyncProduct from "./asyncProduct";
import AsyncHost from "./asyncHost";
import AsyncHost2 from "./asyncHost2";
import AsyncHost3 from "./asyncHost3";
import AsyncHost4 from "./asyncHost4";
import {
  InboxOutlined,
  UploadOutlined,
  PlusOutlined,
  CalendarFilled,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { postEvent } from "../../../redux/actions/event.action";
import { ErrorBoundary } from "react-error-boundary";
import ReactRouterPrompt from "react-router-prompt";
import { LoadingOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getParentCategories } from "../../../redux/actions/category.action";
import { getPromoRequest } from "../../../redux/actions/promoRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getEventsBy_Id,
  UpdateEvents,
} from "../../../redux/actions/event.action";
import {
  faAngleLeft,
  faCalendarPlus,
  faCaretRight,
  faShare,
  faTriangleExclamation,
  faXmark,
  faVideo,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
  InputNumber,
  Radio
} from "antd";
import moment from "moment";
import useMediaQuery from "../../../reUsable/useMediaQuery";
import {
  getSavedGeneralCategories,
  getSavedCategories,
  getSavedSubCategories,
} from "../../../redux/actions/newCategory.action";
import SkeletonComponent from "../../../components/SkeletenLoader";
import { getFeeStructure } from "../../../redux/actions/feestructure.action";
import { searchBySkuAndTitle } from "../../../redux/actions/inventoryActive.action";
import { getExternalEventsBy_Id, postExternalEvent, UpdateExternalEvents } from "../../../redux/actions/externalEvent.action";

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export default function ScheduleExternalEvent(props) {
  const { confirm } = Modal;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useNavigate();

  const {
    parentCategory,
    getAllStream,
    promoRequest,
    newGeneralCategories,
    newCategories,
    newSubCategories,
    savedGeneralCategories,
    savedCategory,
    savedSubCategory,
    getStructureFees,
    validate, getInventoryProductsBySkuAndTitle
  } = useSelector((state) => {
    return state;
  });

  const params = useParams();
  const id = params.id;

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  const matches = useMediaQuery("(min-width: 1024px)");
  const deadline = "2018-12-20T07:37:50.886Z";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [streamModal, setIsModalOpenStream] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);
  const [dataDetails, setDataDetail] = useState([]);
  const [referralPercent, setReferralPercent] = useState("0");
  const [discount, setDiscount] = useState("");
  const [influencer_percent, setInfluencer_percent] = useState("0");
  const [promo, setPromo] = useState("");
  const [loading, setLoading] = useState(false);
  const [multipleHosts, setMultipleHosts] = useState(false);
  const [top, setTop] = useState(30);
  const [dateTime, setDate] = useState(moment());
  const [formState, setFormState] = useState("add");
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState([]);
  const [bannerUrl, setBannerUrl] = useState("");
  const [isBanner, setIsBanner] = useState(false);
  const [host, setHost] = useState([]);
  const [hostError, setHostError] = useState(false);
  const [host2, setHost2] = useState([]);
  const [hostError2, setHostError2] = useState(false);
  const [host3, setHost3] = useState([]);
  const [hostError3, setHostError3] = useState(false);
  const [host4, setHost4] = useState([]);
  const [hostError4, setHostError4] = useState(false);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState("");
  const [promptModal, setPromptModal] = useState(true);
  const [flag, setFlag] = useState(false);
  const [imageUpload, setImageUpload] = useState(false);
  const [sampleBanner, setSampleBanner] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [note, setEventNote] = useState("");
  const [thankyou, setEventThankyou] = useState("");
  const [infoTitle, setInfoTitle] = useState("");
  const [infoDesc, setInfoDesc] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [platform, setPlatform] = useState(false);
  const [streamUrl, setStreamUrl] = useState("external");
  const [streamType, setStreamType] = useState("manual");
  const [streamUrlOrientation, setStreamUrlOrientation] = useState("landscape");
  const [externalUrl, setExternalUrl] = useState("");
  const [productPromoCodeDscs, setProductPromoCodeDscs] = useState("0%");
  const [productPromoCodePromo, setproductPromoCodePromo] = useState("KB0");
  const [gender, setGender] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [genLoad, setGenLoad] = useState(true);
  const [catLoad, setCatLoad] = useState(false);
  const [catSubLoad, setSubCatLoad] = useState(false);
  const [load, setLoad] = useState(false);
  const [dateOnly, setDateOnly] = useState(moment());
  const [timeOnly, setTimeOnly] = useState(moment().clone().add(30, 'minutes'));
  const [endDateOnly, setEndDateOnly] = useState(moment());
  const [endTimeOnly, setEndTimeOnly] = useState(moment().clone().add(60, 'minutes'));




  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOkDetail = () => {
    setIsModalOpenDetail(false);
  };

  const OpenSubmit = () => {
    setIsCheckedOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

    history("/external-events");
  };

  const handleCancelDetail = () => {
    setIsModalOpenDetail(false);
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false);
    setPlatform(false);
  };

  const handleOkInfo = () => {
    setIsCheckedOpen(false);
  };

  const onToggle = (record, recInd) => {
    if (record?.is_active == true) {
      record.is_active = false;
      let obj = record;
      const key = { ...obj };
      key.is_active = false;

      setSku((current) =>
        current.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: false };
          }
          return obj;
        })
      );
    } else {
      record.is_active = true;
      let obj = record;
      const key = { ...obj };
      key.is_active = true;
      setSku((current) =>
        current.map((obj, index) => {
          if (index == recInd) {
            return { ...obj, is_active: true };
          }
          return obj;
        })
      );
    }

    // record.is_active = checked;
  };

  const onDateTimeChange = (e) => {
    console.log(e._d, 'e._d');

    if (e !== null) {
      setDate(e._d);
    } else {
      setDate("");
    }
  };
  const onDateTimeChange2 = (e, type) => {
    if (e !== null) {
      if (type === 'time') setTimeOnly(moment(e._d)
        // .format('HH:mm:ss [GMT]Z')
      )
      if (type === 'date') {
        setDateOnly(moment(e._d)
          // .format('dddd MMM DD YYYY')
        )
        form.setFieldsValue({
          time: moment().clone().add(30, 'minutes')
        })
        setTimeOnly(moment().clone().add(30, 'minutes'))
      }
      setDate(e._d);
    } else {
      setDate("");
    }
  };
  const onDateTimeChangeAuto = (e, type) => {
    if (e !== null) {
      if (type === 'startTime') {
        setTimeOnly(moment(e._d)
          // .format('HH:mm:ss [GMT]Z')
        )
        form.setFieldsValue({
          endtime: moment(e._d).clone().add(30, 'minutes'),
        })
        setEndTimeOnly(moment(e._d).clone().add(30, 'minutes')
          // .format('HH:mm:ss [GMT]Z')
        )
      }
      else if (type === 'startDate') {
        setDateOnly(moment(e._d)
          // .format('dddd MMM DD YYYY')
        )
        form.setFieldsValue({
          time: moment().clone().add(30, 'minutes'),
          enddate: moment(e._d),
        })
        setTimeOnly(moment().clone().add(30, 'minutes'))
        setEndDateOnly(moment(e._d))
      }
      else if (type === 'endDate') {
        setEndDateOnly(moment(e._d)
          // .format('dddd MMM DD YYYY')
        )
        form.setFieldsValue({
          // endtime: moment().clone().add(30, 'minutes'),
          enddate: moment(e._d),
        })

      }
      setDate(e._d);
    } else {
      setDate("");
    }
  };

  useEffect(() => {
    const date = moment(dateOnly, 'dddd MMM DD YYYY');


    const timeParts = moment(timeOnly).format('HH:mm:ss [GMT]Z').split(' ');
    const time = moment(`${date.format('YYYY-MM-DD')} ${timeParts[0]}`);
    const offset = timeParts[1];


    time.utcOffset(offset);


    const dateTime = date.set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    });

    // setDate(dateTime.format('dddd MMM DD YYYY HH:mm:ss [GMT]Z'));
    setDate(dateTime);

    var travelTime = moment().add(30, "minutes");

    var diffTime = dateTime.diff(moment(travelTime), 'minutes');
    // console.log(diffTime, 'diffTime');
    // checkDateAndTime(diffTime)
    if (diffTime < 30) {
      // console.log('less than 30');
      // return Promise.reject(
      //   new Error("Schedule the event after 30 minutes")
      // );
    } else {
      // return Promise.resolve();
    }
  }, [dateOnly, timeOnly]);


  const checkDateAndTime = (_, value) => {
    const date = moment(dateOnly, 'dddd MMM DD YYYY');


    const timeParts = moment(timeOnly).format('HH:mm:ss [GMT]Z').split(' ');
    const time = moment(`${date.format('YYYY-MM-DD')} ${timeParts[0]}`);
    const offset = timeParts[1];


    time.utcOffset(offset);


    const dateTime = date.set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    });

    setDate(dateTime.format('dddd MMM DD YYYY HH:mm:ss [GMT]Z'));

    var travelTime = moment().add(30, "minutes");

    var diffTime = dateTime.diff(moment(travelTime), 'minutes');
    if (diffTime < 0) {
      return Promise.reject(new Error('Schedule the event after 30 minutes'));
    }
    return Promise.resolve();
  };


  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: "SKU",
      dataIndex: "ProductSku",
      key: "ProductSku",
      width: 60,
    },
    {
      title: "IMAGE",
      dataIndex: "mediaUrl",
      key: "mediaUrl",
      width: 70,
      render: (text, record) => {
        return (
          <div className="listing-image inventory-image">
            <Image src={`${record.mediaUrl}`} />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "productDesc",
      key: "productDesc",
      width: 250,
      render: (text, record) => {
        return <div className="desc-limit">{record.productDesc}</div>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (text, record) => {
        return <div className="desc-limit">${record.price}</div>;
      },
    },
    {
      title: "Balance",
      dataIndex: "event",
      key: "event",
      width: 140,
      render: (text, record) => (
        <>
          <div className="balance-area">
            <div className="balance-count">
              {record?.variants?.reduce((acc, item) => {
                return (acc = acc + item.inventory_quantity);
              }, 0)}
            </div>
            <div className="balance-area">
              <a
                className="balance-btn default-btn outline small-btn"
                onClick={() => {
                  showModalDetail(record);
                }}
              >
                View
                {/* <FontAwesomeIcon icon={faEye} /> */}
              </a>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "event",
      key: "event",
      width: 100,
      render: (text, record) => {
        let status = record?.variants?.reduce((acc, item) => {
          return (acc = acc + item.inventory_quantity);
        }, 0);
        if (status >= 70) {
          return (
            <div className="status-info">
              <div className="status-flag green"></div>
              {/* <p>green</p> */}
            </div>
          );
        } else if (status >= 50 && status <= 70) {
          return (
            <div className="status-info">
              <div className="status-flag yellow"></div>
              {/* <p>yellow</p> */}
            </div>
          );
        } else if (status <= 50) {
          return (
            <div className="status-info">
              <div className="status-flag red"></div>
              {/* <p>red</p> */}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   fixed: "right",
    //   width: 100,
    //   className: "text-left",
    //   // sorter: true,
    //   render: (text, record, index) => {
    //     return (
    //       <Space size="middle" className="actions-list">
    //         {/* <DeleteFilled onClick={() => alert("Sure!")} /> */}
    //         <Switch
    //           disabled={isModalOpen}
    //           size="small"
    //           checked={record?.is_active}
    //           onChange={() => onToggle(record, index)}
    //           // checkedChildren="Fixed Top"
    //           // unCheckedChildren="Fixed Top"

    //           // onChange={() => {
    //           //   setFixedTop(!fixedTop);
    //           // }}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];

  const columnDetails = [
    {
      title: "Size",
      key: "option1",
      dataIndex: ["option1"],
      width: 40,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option1 ? record?.option1 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Wash",
      key: "option1",
      dataIndex: ["option1"],
      width: 100,
      render: (text, record, index) => {
        return (
          <>
            <div className=""> {record?.option2 ? record?.option2 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Inseam",
      key: "option1",
      dataIndex: ["option1"],
      width: 70,
      render: (text, record, index) => {
        return (
          <>
            <div className="">{record?.option3 ? record?.option3 : "-"} </div>
          </>
        );
      },
    },

    {
      title: "Stock",
      dataIndex: "inventory_quantity",
      key: "inventory_quantity",
      width: 60,
    },
  ];

  const getSku = (skuData) => {
    setSkuError(false);
    setSku(skuData);
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );
    setParent(parent[0]?.category_name);
  };

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value);
  };

  const changePromoCode = (e, options, name, index) => {
    if (e === undefined) {
    } else {
      var values = e.value.split(" ");
      var discount = values[0];

      setProductPromoCodeDscs(discount);
      setproductPromoCodePromo(e.children);
    }
  };

  const getHostPrimary = (hostData) => {
    setHostError(false);
    if (hostData[0] == null) {
      setHost([]);
    } else {
      setHost(hostData);
    }
  };

  const getHost2 = (hostData) => {
    setHostError2(false);
    if (hostData[0] == null) {
      setHost2([]);
    } else {
      setHost2(hostData);
    }
  };

  const getHost3 = (hostData) => {
    setHostError3(false);
    if (hostData[0] == null) {
      setHost3([]);
    } else {
      setHost3(hostData);
    }
  };

  const getHost4 = (hostData) => {
    setHostError4(false);
    if (hostData[0] == null) {
      setHost4([]);
    } else {
      setHost4(hostData);
    }
  };

  const showModalDetail = (s) => {
    setDataDetail(s);
    setIsModalOpenDetail(true);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadProps = {
    accept: "image/jpeg, image/png, image/jpg, image/svg+xml, image/svg",
    name: "file",
    multiple: false,
    listType: "picture",
    // fileList={fileList}
    maxCount: 1,
    action: "https://httpbin.org/post",

    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/svg+xml" ||
        file.type === "image/svg";

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      setFlag(true);
      setImageUpload(true);
      const { status } = info?.file;

      if (status !== "uploading") {
        setBanner(info?.file?.originFileObj);
        var reader = new FileReader();
        reader.readAsDataURL(info?.file?.originFileObj);
        reader.onload = function () {
          setBannerUrl(reader.result);
          setIsBanner(true)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }

      if (status === "done") {
        message.success(`${info?.file.name} file uploaded successfully.`);
        setImageUpload(false);
        setPreviewImage(info?.file?.response?.files?.banner);
      } else if (status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      setIsBanner(false)
      setBannerUrl("");
    },

    onRemove(e) {
      setIsBanner(false)
      setBannerUrl("");
      setPreviewImage("");

    },
  };


  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      setLoading(true);

      const { title, gen_category_id, category_id, sub_category_id, Externalstream } = values;
      let formData = new FormData();

      let newSku = sku.map((skuItem) => {
        return {
          ...skuItem,
          // influencer_percent: influencer_percent ? influencer_percent : "0",
          // referral_percent: referralPercent ? referralPercent : "0",
          influencer_percent:
            getStructureFees?.payload?.message?.fee_structure
              ?.content_creator_fee > 0
              ? getStructureFees?.payload?.message?.fee_structure
                ?.content_creator_fee
              : "0",
          referral_percent:
            getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0
              ? getStructureFees?.payload?.message?.fee_structure?.referral_fee
              : "0",
          cashback_percent:
            getStructureFees?.payload?.message?.fee_structure
              ?.shopper_cashback > 0
              ? getStructureFees?.payload?.message?.fee_structure
                ?.shopper_cashback
              : "0",
        };
      });
      formData.append("image", banner);
      formData.append("id", newSku[0]?.id);
      // formData.append("stream_type", streamUrl);
      formData.append("control", streamType);
      formData.append("playback_url", Externalstream);
      formData.append("orientation", streamUrlOrientation);
      // formData.append("primary_host", JSON.stringify(host));
      // formData.append("host1", JSON.stringify(host2));
      // formData.append("host2", JSON.stringify(host3));
      // formData.append("host3", JSON.stringify(host4));
      formData.append("products", JSON.stringify(newSku));
      formData.append("title", title);
      formData.append("notes", note);
      // formData.append("promo", promo);
      // formData.append("discount", (discount += "%"));
      formData.append("discount", productPromoCodeDscs);
      formData.append("promo", productPromoCodePromo);
      // formData.append("referral_percent", referralPercent);
      // formData.append("influencer_percent", influencer_percent);
      formData.append(
        "influencer_percent",
        getStructureFees?.payload?.message?.fee_structure?.content_creator_fee >
          0
          ? getStructureFees?.payload?.message?.fee_structure
            ?.content_creator_fee
          : 0
      );
      formData.append(
        "referral_percent",
        getStructureFees?.payload?.message?.fee_structure?.referral_fee > 0
          ? getStructureFees?.payload?.message?.fee_structure?.referral_fee
          : 0
      );
      formData.append(
        "cashback_percent",
        getStructureFees?.payload?.message?.fee_structure?.shopper_cashback > 0
          ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback
          : 0
      );

      formData.append("thankyou_message", thankyou);
      formData.append("start_date", moment(dateTime)?.toISOString());
      formData.append("gen_category_id", gen_category_id);

      // if (category_id !== "all") {
      formData.append("category_id", category_id ? category_id : "all");
      // }
      // if (sub_category_id !== "all") {
      formData.append(
        "sub_category_id",
        sub_category_id ? sub_category_id : "all"
      );
      // }
      formData.append("default_banner", sampleBanner);
      formData.append("is_facebook_live", platform);
      formData.append("multiple_hosts", multipleHosts);
      if (platform) {
        formData.append(
          "facebook",
          JSON.stringify({ title: infoTitle, description: infoDesc })
        );
      }
      // formData.append('social_platform', platform);
      if (formState === "edit") {
        dispatch(UpdateExternalEvents(formData, id)).then((res) => {
          if (res.type == "UPDATE_EXTERNAL_EVENT_SUCCESS") {
            setPromptModal(false);
            notification.success({
              message: "Event Updated Successfully",
              className: "toast-success",
            });

            showModal();
            // setSpin(false);
            setLoading(false);

            // handleClose();
            // history("/external-events");
          } else {
            // setSpin(false);
            setLoading(false);
            notification.error({
              message: "Event Failed",
              className: "toast-error",
            });
          }
        });
      } else {
        dispatch(postExternalEvent(formData)).then((res) => {
          if (res.type == "POST_EXTERNAL_EVENT_SUCCESS") {
            setPromptModal(false);
            notification.success({
              message: res?.payload?.message,
              className: "toast-success",
            });
            showModal();
            // setSpin(false);
            setLoading(false);

            // handleClose();
            //history("/home");
          } else {
            // setSpin(false);
            setLoading(false);
            notification.error({
              message: "Event Failed",
              className: "toast-error",
            });
          }
        });
      }
    },
    total: 3,
  });
  useEffect(() => {
    dispatch(getSavedGeneralCategories()).then((res) => {
      if (res.success) {
        setGenLoad(false);
      } else {
        setGenLoad(false);
      }
    });
    // dispatch(getPromoRequest()).then((res) => {});
    dispatch(getFeeStructure()).then((res) => {
    });
    dispatch(searchBySkuAndTitle({ sku: "", sort_on: "stock", sort: "asc", is_orme_product: true }, 1, 1)).then((res) => {
      if (res?.success) {
      } else {
        console.log(res, "error");
      }
    });
  }, []);

  useEffect(() => {
    setLoad(true);
    if (id) {

      setFormState("edit");
      dispatch(getExternalEventsBy_Id(id)).then(async (event) => {
        if (event.type === "GET_EXTERNAL_EVENT_ID_SUCCESS") {
          form.setFieldsValue({
            ExternalStreamOrientation: event.payload.message[0]?.event?.orientation,
            title: event.payload.message[0]?.event?.title,
            notes: event.payload.message[0]?.event?.notes,
            stream: event.payload.message[0]?.event?.stream_type,
            Externalstream: event.payload.message[0]?.event?.playback_url,
            streamType: event.payload.message[0]?.event?.control,
            // referral_percent:
            //   event.payload.message[0]?.event?.referral_percent === "0"
            //     ? null
            //     : event.payload.message[0]?.event?.referral_percent,
            promo:
              event.payload.message[0]?.event?.promo === ""
                ? "0"
                : event.payload.message[0]?.event?.promo,
            discount:
              event.payload.message[0]?.event?.discount === ""
                ? "0"
                : event.payload.message[0]?.event?.discount,
            // influencer_percent:
            //   event.payload.message[0]?.event?.influencer_percent === "0"
            //     ? null
            //     : event.payload.message[0]?.event?.influencer_percent,

            thankyou_message: event.payload.message[0]?.event?.thankyou_message,
            datetime: moment(
              moment(event.payload.message[0]?.event?.start_date).format(
                "MM/DD/YYYY hh:mm a"
              )
            ),
            banner: event.payload?.message[0]?.event?.banner,
            gen_category_id: event.payload?.message[0]?.event?.gen_category_id,
            category_id: event.payload?.message[0]?.event?.category_id
              ? event.payload?.message[0]?.event?.category_id
              : "all",
            sub_category_id: event.payload?.message[0]?.event?.sub_category_id
              ? event.payload?.message[0]?.event?.sub_category_id
              : "all",
            default_banner: event.payload?.message[0]?.event?.default_banner,
            date: moment(event.payload?.message[0]?.event?.start_date),
            time: moment(event.payload?.message[0]?.event?.start_date)
          });
          if (event.payload?.message[0]?.event?.gen_category_id) {
            dispatch(
              getSavedCategories(
                event.payload?.message[0]?.event?.gen_category_id
              )
            ).then((res) => {
              if (res.success) {
                setCategoryOptions(res?.message);
                // setCatLoad(false)
              } else {
                // setCatLoad(true)
              }
            });
            dispatch(
              getSavedSubCategories(
                event.payload?.message[0]?.event?.gen_category_id,
                event.payload?.message[0]?.event?.category_id
                  ? event.payload?.message[0]?.event?.category_id
                  : ""
              )
            ).then((res) => {
              if (res.success) {
                setSubCategoryOptions(res?.message);
                // setCatLoad(false)
              } else {
                // setCatLoad(true)
              }
            });
          }
          setSampleBanner(
            event.payload?.message[0]?.event?.default_banner ? true : false
          );

          setPreviewImage(event.payload?.message[0]?.event?.banner);
          setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
          setEventTitle(event.payload.message[0]?.event?.title);
          setEventNote(event.payload.message[0]?.event?.notes);
          setproductPromoCodePromo(event.payload.message[0]?.event?.promo);
          setProductPromoCodeDscs(event.payload.message[0]?.event?.discount);

          setPlatform(
            event.payload.message[0]?.event?.is_facebook_live ? true : false
          );
          if (event.payload.message[0]?.event?.is_facebook_live) {
            setInfoTitle(event.payload.message[0]?.event?.facebook?.title);
            setInfoDesc(event.payload.message[0]?.event?.facebook?.description);
          }

          setReferralPercent(
            event.payload.message[0]?.event?.referral_percent === ""
              ? "0"
              : event.payload.message[0]?.event?.referral_percent
          );
          setPromo(
            event.payload.message[0]?.event?.promo === ""
              ? "0"
              : event.payload.message[0]?.event?.promo
          );
          setInfluencer_percent(
            event.payload.message[0]?.event?.influencer_percent === ""
              ? "0"
              : event.payload.message[0]?.event?.influencer_percent
          );
          setDiscount(
            event.payload.message[0]?.event?.discount === ""
              ? "0"
              : event.payload.message[0]?.event?.discount
          );
          //setStreamUrl(event.payload.message[0]?.event?.stream_type);
          setMultipleHosts(
            event.payload.message[0]?.event?.multiple_hosts
              ? event.payload.message[0]?.event?.multiple_hosts
              : false
          );
          setStreamUrl("external");
          setStreamUrlOrientation(event.payload.message[0]?.event?.orientation);
          setStreamType(event.payload.message[0]?.event?.control)
          setExternalUrl(event.payload.message[0]?.event?.streaming_url);
          setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
          setTimeOnly(moment(moment(event.payload.message[0]?.event?.start_date)));
          setDateOnly(moment(moment(event.payload.message[0]?.event?.start_date)));
          setBanner(event.payload?.message[0]?.event?.banner);
          setSku(event.payload.message[0]?.event?.products);
          let primary_data =
            (await event.payload?.message[0]?.hosts[1]?.primary) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let primary_host = await primary_data.filter(
            (num) => num.primary === true
          );

          let host2_data =
            (await event.payload?.message[0]?.hosts[2]?.host1) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let host_2 = await host2_data.filter((num) => num.host1 === true);

          let host_3 = [];
          if (event.payload?.message[0]?.hosts[2]?.host2 === true) {
            let host3_data =
              (await event.payload?.message[0]?.hosts[2]?.host2) === true
                ? event.payload?.message[0]?.hosts
                : [];
            host_3 = await host3_data.filter((num) => num.host2 === true);
          }

          if (event.payload?.message[0]?.hosts[3]?.host2 === true) {
            let host3_data =
              (await event.payload?.message[0]?.hosts[3]?.host2) === true
                ? event.payload?.message[0]?.hosts
                : [];
            host_3 = await host3_data.filter((num) => num.host2 === true);
          }

          let host_4 = [];
          if (event.payload?.message[0]?.hosts[2]?.host3 === true) {
            let host4_data =
              (await event.payload?.message[0]?.hosts[2]?.host3) === true
                ? event.payload?.message[0]?.hosts
                : [];
            host_4 = await host4_data.filter((num) => num.host3 === true);
          }

          if (event.payload?.message[0]?.hosts[3]?.host3 === true) {
            let host4_data =
              (await event.payload?.message[0]?.hosts[3]?.host3) === true
                ? event.payload?.message[0]?.hosts
                : [];
            host_4 = await host4_data.filter((num) => num.host3 === true);
          }

          if (event.payload?.message[0]?.hosts[4]?.host3 === true) {
            let host4_data =
              (await event.payload?.message[0]?.hosts[4]?.host3) === true
                ? event.payload?.message[0]?.hosts
                : [];
            host_4 = await host4_data.filter((num) => num.host3 === true);
          }
          setHost(primary_host);
          setHost2(host_2);
          setHost3(host_3);
          setHost4(host_4);
          setFormState("edit");

          let parent = await parentCategory?.payload?.message.filter(
            (item) =>
              item.category_id == event.payload?.message[0]?.event?.category_id
          );
          setParent(parent[0]?.category_name);

          let filteredUsers = savedGeneralCategories?.message.filter((user) => {
            return user.category_id === form.getFieldValue().gen_category_id;
          });

          if (filteredUsers.length > 0) {
            setParent(filteredUsers[0]?.name)
            form.setFieldsValue({ category_id_name: filteredUsers[0]?.name });
          }
        }
      });
      setLoad(false);
    } else {
      form.setFieldsValue({
        title: null,
        datetime: null,
        category_id: undefined,
        banner: null,
      });
      setFormState("add");
      setLoad(false);
    }
    // dispatch(getSavedGeneralCategories()).then((res) => {
    //   if (res.success) {
    //     setGenLoad(false);
    //   } else {
    //     setGenLoad(false);
    //   }
    // });
  }, [id]);

  function startDateLimit(current) {
    return current && current < moment().startOf("day");
  }
  function endDateLimit(current) {
    return current && current < moment(dateOnly).startOf("day");
  }

  const handleMultipleHosts = (e) => {
    setMultipleHosts(e);
    setHost2([]);
    setHost3([]);
    setHost4([]);
  };

  const changeSampleBanner = (e) => {
    if (e.target.checked) {
      setSampleBanner(true);
      setPreviewImage(
        "https://d1hq75pouwojqi.cloudfront.net/eventdefaultbanner/banner.png"
      );
    } else {
      setSampleBanner(false);
      setPreviewImage("");
      setBanner("");
      setBannerUrl("");
      form.setFieldsValue({
        banner: null,
      });
    }
  };

  function dateToFromNowDaily(myDate) {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: "[Last] dddd",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",

      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return "[" + fromNow + "]";
      },
    });
  }

  const changePlatform = (e) => {
    if (!validate?.payload?.message?.fb_token) {
      notification.error({
        message: "Please connect facebook to publish on live",
        className: "toast-error",
      });
    } else {
      setPlatform(e.target.checked);
      if (e.target.checked == true) {
        setIsCheckedOpen(true);
      }
    }
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  const handleButtonClick = () => {
    setPromptModal(false);
    confirm({
      wrapClassName: "modal-generic cancel-event-modal min-modal-600",
      title: "Warning",
      centered: true,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      closeIcon: <FontAwesomeIcon icon={faXmark} />,
      content: "Are you sure you want to exit the event ?",
      okText: " Yes, Exit",
      okType: "danger",
      cancelText: " No, I don't want to exit ",

      onOk() {
        history("/external-events");
      },
      onCancel() {
        setPromptModal(true);
      },
    });
  };


  const onGenderChange = (value) => {
    setGender(value);
    setSubCategoryOptions([]);
    setCategoryOptions([]);
    setCategory("");
    setSubCategory("");
    let filteredUsers = savedGeneralCategories?.message.filter((user) => {
      return user.category_id === value;
    });

    if (filteredUsers.length > 0) {
      setParent(filteredUsers[0]?.name)
      form.setFieldsValue({ category_id_name: filteredUsers[0]?.name });
    }
    form.setFieldsValue({ category_id: undefined, sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setCatLoad(true);
    dispatch(getSavedCategories(data)).then((res) => {
      if (res.success) {
        setCategoryOptions(res?.message);
        setCatLoad(false);
      } else {
        setCatLoad(false);
      }
    });
  };

  const onCategoryChange = (value) => {
    setSubCategory([]);
    setSubCategoryOptions([]);
    setSubCategory("");
    form.setFieldsValue({ sub_category_id: undefined });
    const data = value === "all" ? "" : value;
    setSubCatLoad(true);
    dispatch(getSavedSubCategories(gender === "all" ? "" : gender, data)).then(
      (res) => {
        if (res.success) {
          setSubCategoryOptions(res?.message);
          setSubCatLoad(false);
        } else {
          setSubCatLoad(false);
        }
      }
    );
  };

  const onSubCategoryChange = (value) => {
    setSubCategory(value);
  };
  const formList = [
    <>
      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Event Information</h5>
          <p>
            Make a major impact with your opening line! Give your event an
            engaging title.
            {/* Make a bold statement with your introduction line ! The event title
            will appear on your tab, your social share and the homescreen name.
            So stand out, make your entrance to the world 😃 😃{" "} */}
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Event Title"
            initialValue={
              formState === "add" ? null : form.getFieldValue().title
            }
            name="title"
            rules={[
              {
                required: true,
                message: "Please Enter Event Title",
              },
            ]}
          >
            <Input
              placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
              size="large"
              showCount
              maxLength={80}
              onChange={(e) => {
                setEventTitle(e.target.value);
                setFlag(true);
              }}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Categories</h5>
          <p>Select category from provided list.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().gen_category_id}
            label="Department"
            name="gen_category_id"
            rules={[
              {
                required: true,
                message: "Please select a department",
              },
            ]}
          >
            <Select
              className="select-style dark-field"
              placeholder="Select Department"
              size="large"
              focus={false}
              onBlur={false}
              onChange={onGenderChange}
              value={gender}
              loading={genLoad}
              disabled={genLoad}
            >
              {savedGeneralCategories?.message?.map((option) => {
                return (
                  <Option value={option?.category_id}>{option?.name}</Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().category_id}
            label="Category"
            name="category_id"
            rules={[
              {
                // required: true,
                message: "Please select a category",
              },
            ]}
          >
            <Select
              placeholder="Select Category"
              size="large"
              disabled={categoryOptions?.length ? false : true}
              className="select-style dark-field"
              onChange={onCategoryChange}
              loading={catLoad}
              value={category ? category : undefined}
              defaultValue={"all"}
            >
              <Option value="all">All</Option>
              {categoryOptions?.map((option) => {
                return (
                  <Option value={option?.category_id}>
                    {option?.gen_category?.name} {" > "}
                    {option?.category_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().sub_category_id}
            label="Sub Category"
            name="sub_category_id"
            rules={[
              {
                // required: true,
                message: "Please select a sub category",
              },
            ]}
          >
            <Select
              placeholder="Sub Category"
              size="large"
              disabled={subCategoryOptions?.length ? false : true}
              className="select-style dark-field"
              onChange={onSubCategoryChange}
              loading={catSubLoad}
              value={subCategory ? subCategory : undefined}
              defaultValue={"all"}
            >
              <Option value="all">All</Option>
              {subCategoryOptions?.map((option) => {
                return (
                  <Option value={option?.sub_category_id}>
                    {option?.gen_category?.name} {" > "}
                    {option?.category?.category_name} {" > "}
                    {option?.sub_category_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column flex-stretch">
          <h5 className="rounded">Details</h5>
          <p>
            This information will be visible to your audience in ‘Details’ tab
            when event is Live.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Details"
            initialValue={
              formState === "add" ? null : form.getFieldValue().notes
            }
            name="notes"
          >
            <TextArea
              showCount
              maxLength={200}
              // onChange={onChange}
              className="dark-field"
              // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
              placeholder="Enter Details"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => {
                setEventNote(e.target.value);
                setFlag(true);
              }}
            />
          </Form.Item>
        </div>
      </div>


      {/* <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Stream Type</h5>
          <p>Select stream type: Manual or Auto</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Form.Item name="streamType" labelCol={{ span: 24 }} label="Stream Type">
            <Radio.Group
              className="streaming-checkbox"
              defaultValue="manual"
              options={[
                {
                  label: 'Manual',
                  value: 'manual',
                },
                {
                  label: 'Auto',
                  value: 'auto',
                  disabled: true
                }
              ]}
              onChange={(value) => setStreamType(value.target.value)}
              value={streamType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </div>
      </div> */}
      {
        // streamType == 'manual' &&
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Date and Time</h5>
            <p>Set the date and time of the event.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai row">
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="date"
                initialValue={formState === 'add' ? dateOnly : form.getFieldValue('datetime')}
                rules={[
                  {
                    required: true,
                    message: 'Please select date',
                  },
                ]}
                labelCol={{ span: 24 }}
                label="Date"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    onDateTimeChange2(e, 'date');
                    setFlag(true);
                  }}
                  disabledDate={startDateLimit}
                  format="MM-DD-YYYY"
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="time"
                initialValue={formState === 'add' ? timeOnly : form.getFieldValue('datetime')}
                rules={[
                  {
                    required: true,
                    message: 'Please select time',
                  },
                  {
                    validator: checkDateAndTime
                  }
                  // {
                  //   validator: async (_, datetime) => {
                  //     console.log(dateTime, 'dateTime');

                  //     var travelTime = moment().add(30, "minutes");
                  //     var diffTime = travelTime.diff(moment(datetime), 'minutes');
                  //     if (diffTime <= 30) {
                  //       return Promise.reject(
                  //         new Error("Schedule the event after 30 minutes")
                  //       );
                  //     } else {
                  //       return Promise.resolve();
                  //     }
                  //   },
                  // },
                ]}
                labelCol={{ span: 24 }}
                label="Time"
              >
                <TimePicker
                  // popupClassName="single-column-timepicker"
                  style={{ width: '100%' }}
                  use12Hours
                  showTime={{
                    use12Hours: true, format: "HH:mm",
                    // minuteStep: 30,
                  }}
                  format="h:mm A"
                  onChange={(e) => {
                    onDateTimeChange2(e, 'time');
                    setFlag(true);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      }
      {
        streamType == 'auto' &&
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Date and Time</h5>
            <p>Set the date and time of the event.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
            <div className="row">
              <div className="col-md-3 col-xl-3">
                <Form.Item
                  name="date"
                  initialValue={formState === 'add' ? dateOnly : form.getFieldValue('datetime')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select start date',
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label="Start Date"
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      onDateTimeChangeAuto(e, 'startDate');
                      setFlag(true);
                    }}
                    disabledDate={startDateLimit}
                    format="MM-DD-YYYY"
                  />
                </Form.Item>
              </div>
              <div className="col-md-3 col-xl-3">
                <Form.Item
                  name="time"
                  initialValue={formState === 'add' ? timeOnly : form.getFieldValue('datetime')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select start time',
                    },
                    {
                      validator: checkDateAndTime
                    }
                  ]}
                  labelCol={{ span: 24 }}
                  label="Start Time"
                >
                  <TimePicker
                    // popupClassName="single-column-timepicker"
                    style={{ width: '100%' }}
                    use12Hours
                    showTime={{
                      use12Hours: true, format: "HH:mm",
                      // minuteStep: 30,
                    }}
                    format="h:mm A"
                    onChange={(e) => {
                      onDateTimeChangeAuto(e, 'startTime');
                      setFlag(true);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-md-3 col-xl-3">
                <Form.Item
                  name="enddate"
                  initialValue={formState === 'add' ? endDateOnly : form.getFieldValue('datetime')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select end date',
                    },
                    {
                      validator: async (_, value) => {
                        if (value && value?.isBefore(dateOnly)) {
                          console.log('err');
                          Promise.reject(
                            new Error("End date must be greater than start date")
                          );
                        } else {
                          Promise.resolve();
                        }
                      }
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label="End Date"
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      onDateTimeChangeAuto(e, 'endDate');
                      setFlag(true);
                    }}
                    disabledDate={endDateLimit}
                    format="MM-DD-YYYY"
                  />
                </Form.Item>
              </div>
              <div className="col-md-3 col-xl-3">
                <Form.Item
                  name="endtime"
                  initialValue={formState === 'add' ? endTimeOnly : form.getFieldValue('datetime')}
                  rules={[
                    {
                      required: true,
                      message: 'Please select end time',
                    },
                    {
                      validator: checkDateAndTime
                    }
                  ]}
                  labelCol={{ span: 24 }}
                  label="End Time"
                >
                  <TimePicker
                    // popupClassName="single-column-timepicker"
                    style={{ width: '100%' }}
                    use12Hours
                    showTime={{
                      use12Hours: true, format: "HH:mm",
                      // minuteStep: 30,
                    }}
                    format="h:mm A"
                    onChange={(e) => {
                      onDateTimeChangeAuto(e, 'endTime');
                      setFlag(true);
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      }
      {/* 
      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Platform</h5>
          <p>Set the platform to which the event will be published.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Checkbox checked={platform} onChange={changePlatform}>
            Facebook
          </Checkbox>
        </div>
      </div> */}

      {/* <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Streaming Format</h5>
          <p>
            Determine the format for your live stream whether you'll have one
            primary host or multiple hosts.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Checkbox
            disabled={formState === "edit"}
            checked={!multipleHosts}
            onChange={(e) => handleMultipleHosts(!e.target.checked)}
          >
            Single
          </Checkbox>
          <Checkbox
            disabled={formState === "edit"}
            checked={multipleHosts}
            onChange={(e) => handleMultipleHosts(e.target.checked)}
          >
            Multiple
          </Checkbox>
          {formState === "edit" && (
            <p className="mt-3 text-danger">Can not be changed.</p>
          )}
        </div>
      </div> */}

      {
        streamUrl == "external" &&
        (
          <div className="row d-flex mb-20">
            <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
              <h5 className="rounded">External Stream</h5>
              <p>Put Url to show Stream on Live Event</p>
            </div>
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">

              <Form.Item
                name="ExternalStreamOrientation"
                labelCol={{ span: 24 }}
                label="External Stream Orientation"
              >
                <Radio.Group
                  onChange={(value) => setStreamUrlOrientation(value.target.value)}
                  value={streamUrlOrientation}
                  defaultValue="landscape"
                  options={[
                    {
                      label: 'Landscape',
                      value: 'landscape',
                    },
                    {
                      label: 'Portrait',
                      value: 'portrait',
                    }
                  ]}
                >
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="Externalstream"
                labelCol={{ span: 24 }}
                label="External Stream"
                rules={[
                  {
                    required: streamUrl == "external" ? true : false,
                    message: "Please enter external stream url",
                  },
                  {
                    pattern: /^(https?:\/\/)?(www\.)?([\da-z.-]+)?(\.[a-z.]{2,6})?(\/[\w .-]*)*\/?.*\.m3u8$/,
                    message: 'Enter a valid .m3u8 URL!',
                  }
                ]}
              >
                <Input
                  placeholder="Enter External Url"
                  size="large"
                // defaultValue={validate?.payload?.message?.name}
                />
              </Form.Item>
            </div>
          </div>
        )}
      {/* {
        // streamUrl === "local" && 
        (
          <div className="row d-flex mb-30">
            <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
              <h5 className="rounded">Assign Host</h5>
              <p>
                Specify event Host. Please note these Hosts’ event addresses must
                be registered on ormelive.com as a Shopper or an Influencer.
              </p>
            </div>
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              <div className="row">
                <div className="col-md-6 col-xl-6">
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Brand"
                    name="producer"
                  >
                    <Input
                      placeholder="Producer"
                      size="large"
                      disabled
                      defaultValue={validate?.payload?.message?.name}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-xl-6">
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label="Producer"
                    name="producer"
                  >
                    <Input
                      placeholder="Producer"
                      size="large"
                      disabled
                      defaultValue={validate?.payload?.message?.email}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={`${multipleHosts && "row"}`}>
                <div className={`${multipleHosts && "col-md-6 col-xl-6"}`}>
                  <Form.Item
                    name="primary"
                    label="Primary Host"
                    labelCol={{ span: 24 }}
                    className="field-style2"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please select Primary Host",
                  //   },
                  // ]}
                  >
                    {host?.length > 0 && formState == "edit" ? (
                      <>
                        <AsyncHost
                          getHostPrimary={getHostPrimary}
                          host={host}
                          host2={host2}
                          host3={host3}
                          host4={host4}
                          form={formState}
                          flag={setFlag}
                        />
                        {hostError && (
                          <div
                            role="alert"
                            className="ant-form-item-explain-error"
                          >
                            Please select a host
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        <AsyncHost
                          getHostPrimary={getHostPrimary}
                          host={host}
                          host2={host2}
                          host3={host3}
                          host4={host4}
                          form={formState}
                        />
                        {hostError && (
                          <div
                            role="alert"
                            className="ant-form-item-explain-error"
                          >
                            Enter a host Email
                          </div>
                        )}
                      </>
                    )}
                  </Form.Item>
                </div>
                {multipleHosts && (
                  <>
                    <div className="col-md-6 col-xl-6">
                      <Form.Item
                        name="host2"
                        label="Host 2"
                        labelCol={{ span: 24 }}
                        className="field-style2"
                      // className="d-flex flex-column sku-field"
                      >
                        {host2?.length > 0 && formState == "edit" ? (
                          <>
                            <AsyncHost2
                              getHost2={getHost2}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                              flag={setFlag}
                            />
                            {hostError2 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please select a host
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <AsyncHost2
                              getHost2={getHost2}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                            />
                            {hostError2 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Enter a host Email
                              </div>
                            )}
                          </>
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <Form.Item
                        name="host3"
                        label="Host 3"
                        labelCol={{ span: 24 }}
                        className="field-style2"
                      // className="d-flex flex-column sku-field"
                      >
                        {host3?.length > 0 && formState == "edit" ? (
                          <>
                            <AsyncHost3
                              getHost3={getHost3}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                              flag={setFlag}
                            />
                            {hostError3 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please select a host
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <AsyncHost3
                              getHost3={getHost3}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                            />
                            {hostError3 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Enter a host Email
                              </div>
                            )}
                          </>
                        )}
                      </Form.Item>
                    </div>
                    <div className="col-md-6 col-xl-6">
                      <Form.Item
                        name="host4"
                        label="Host 4"
                        labelCol={{ span: 24 }}
                        className="field-style2"
                      // className="d-flex flex-column sku-field"
                      >
                        {host4?.length > 0 && formState == "edit" ? (
                          <>
                            <AsyncHost4
                              getHost4={getHost4}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                              flag={setFlag}
                            />
                            {hostError4 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Please select a host
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <AsyncHost4
                              getHost4={getHost4}
                              host={host}
                              host2={host2}
                              host3={host3}
                              host4={host4}
                              form={formState}
                            />
                            {hostError4 && (
                              <div
                                role="alert"
                                className="ant-form-item-explain-error"
                              >
                                Enter a host Email
                              </div>
                            )}
                          </>
                        )}
                      </Form.Item>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        )} */}

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="#" className="prev-next-link ff-nunito">
            {/* <FontAwesomeIcon icon={faAngleLeft} />
            <span onClick={() => prevStep()}> Previous</span> */}
          </Link>

          <div className="d-flex">
            <Button
              // disabled
              className="default-btn outline d-flex align-items-center justify-content-center res-btn3 span-inner-initial"
              type="primary"
              size="large"
              // onClick={() => gotoStep(current + 1)}
              onClick={handleButtonClick}
            >
              Exit
            </Button>
            <Button
              // disabled
              className="default-btn d-flex align-items-center justify-content-center res-btn2 mr-0"
              type="primary"
              size="large"
              icon={
                <FontAwesomeIcon
                  size="sm"
                  className="event-icon"
                  icon={faCaretRight}
                />
              }
              // onClick={() => gotoStep(current + 1)}
              onClick={() => {
                if (thankyou) {
                  setActiveKey("3")
                }
                if (host?.length === 0) {
                  if (streamUrl === "external") {
                    gotoStep(current + 1);
                  }
                  setHostError(true);
                } else {
                  gotoStep(current + 1);
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </>,

    <>
      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Pre LIVE</h5>
          <p>Upload event banner and let your audience identify your brand.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <h5>Banner</h5>
          <p className="color-light">1280px x 720px (jpg or png format)</p>
          {!sampleBanner && (
            <>
              {banner && typeof banner !== "object" && !previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={banner}
                  />
                </Form.Item>
              )}
              {typeof banner === "object" && previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={previewImage}
                  />
                </Form.Item>
              )}
              {banner == previewImage && banner && previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={previewImage}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Form.Item
                  name="banner"
                  valuePropName={formState !== "edit" && "fileList"}
                  //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                  //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                  getValueFromEvent={normFile}
                  noStyle
                  multiple={false}
                  rules={[
                    {
                      required: true,
                      message: "Please upload banner image",
                    },
                  ]}
                >
                  <Upload.Dragger
                    customRequest={dummyRequest}
                    className={`upload-image-main ${banner?.length !== 0 &&
                      banner !== undefined &&
                      previewImage &&
                      "d-none"
                      }`}
                    {...uploadProps}
                    name="banner"
                    // defaultFileList={
                    //   formState === "edit" &&
                    //   form.getFieldValue()?.banner === null
                    //     ? null
                    //     : [
                    //         {
                    //           url: form.getFieldValue()?.banner,
                    //         },
                    //       ]
                    // }
                    defaultFileList={
                      formState === "edit"
                        ? sampleBanner || form.getFieldValue()?.banner === null
                          ? form.getFieldValue()?.banner
                          : form.getFieldValue()?.banner?.length === 1
                            ? form.getFieldValue()?.banner
                            : [
                              {
                                url: form.getFieldValue()?.banner,
                              },
                            ]
                        : null
                    }
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                    <p className="ant-upload-text">drag files here to upload</p>
                    {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p> */}
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </>
          )}
          {sampleBanner && (
            <Form.Item className="preview-image-main" name="banner">
              <Image
                // width={100}
                // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                src={previewImage}
              />
            </Form.Item>
          )}
          <Checkbox checked={sampleBanner} onChange={changeSampleBanner}>
            Use a sample image
          </Checkbox>
        </div>
      </div>
      <div className="row d-flex mb-30">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Post LIVE</h5>
          <p>
            Thank everyone for attending the event and bring them back to your
            store in future!
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Thank you message"
            className="mb-40"
            initialValue={
              formState === "add" ? null : form.getFieldValue().thankyou_message
            }
            name="thankyou_message"
          >
            <TextArea
              showCount
              maxLength={250}
              // onChange={onChange}
              className="dark-field"
              placeholder="The live is now over. Thank you for coming to our show."
              autoSize={{ minRows: 4, maxRows: 6 }}
              onFocus={() => setActiveKey("3")}
              onChange={(e) => {
                setEventThankyou(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          {imageUpload && !sampleBanner ? (
            <Link to="#" className="prev-next-link ff-nunito" disabled>
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </Link>
          ) : (
            <button
              onClick={() => { gotoStep(current - 1); setActiveKey("1") }}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button>
          )}

          <div className="d-flex">
            <Modal
              maskClosable={false}
              className="modal-generic modal-500"
              centered
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closeIcon={<FontAwesomeIcon icon={faXmark} />}
              footer={false}
            ></Modal>
            <Button
              // disabled
              className="default-btn outline d-flex align-items-center justify-content-center res-btn2 span-inner-initial"
              type="primary"
              size="large"
              onClick={handleButtonClick}
            >
              Exit
            </Button>
            <Button
              // disabled
              className="default-btn d-flex align-items-center justify-content-center res-btn3 mr-0"
              type="primary"
              size="large"
              icon={
                <FontAwesomeIcon
                  size="sm"
                  className="event-icon"
                  icon={faCaretRight}
                />
              }
              onClick={() => gotoStep(current + 1)}
              disabled={imageUpload && !sampleBanner ? true : false}
            >
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </>,

    <>
      {/* <div className="row">
        <div className="col-md-3">
          <label className="pb-0">Promo Code</label>
          <Select
            size="small"
            filterOption={(input, options) =>
              options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={
              productPromoCodePromo ? productPromoCodePromo : "Select PromoCode"
            }
            //disabled={!(formState === "add" || formState === "edit")}
            placeholder="Select PromoCode"
            //loading={this.state.promoCond}
            optionFilterProp="children"
            className="w-100 select-modal mb-20"
            // onSearch={onSearch}
            onChange={(options, e) => changePromoCode(e, options)}
            showSearch
            allowClear={false}
            loading={promoRequest?.loading ? true : false}
            disabled={promoRequest?.loading ? true : false}
          >
            {promoRequest?.message?.map((customer, key) => {
              return (
                <Option key={customer.promo_percent + " " + key}>
                  {customer.promo}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="col-md-3">
          <label>Discount</label>
          <div className="promo_discount form-control mb-20">
            {productPromoCodeDscs}
          </div>
        </div> */}

      {/* <div className="col-md-3">
          <label>Cashback</label>
          <Form.Item
            labelCol={{ span: 24 }}
            // label="Influencer Fee"
            className="mb-20 label-padb-0"
            // initialValue={
            //   formState === "add"
            //     ? null
            //     : form.getFieldValue().influencer_percent
            // }
            initialValue={
              getStructureFees?.payload?.message?.fee_structure?.shopper_cashback ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
            }
            name="influencer_percent"
          >
            <InputNumber
              disabled={true}
              className="w-100 select-modal"
              defaultValue={0}
              size="large"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(e) => {
                setFlag(true);
                setInfluencer_percent(e);
              }}
            />
          </Form.Item>
        </div>
        <div className="col-md-3">
          <label>Referral Fee</label>
          <Form.Item
            labelCol={{ span: 24 }}
            // label="Referral Fee"
            className="mb-20 label-padb-0 w-100"
            // initialValue={
            //   formState === "add" ? null : form.getFieldValue().referral_percent
            // }
            initialValue={
              getStructureFees?.payload?.message?.fee_structure?.referral_fee ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
            }
            name="referral_percent"
          >
            <InputNumber
              disabled
              className="w-100 select-modal"
              defaultValue={0}
              size="large"
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(e) => {
                setReferralPercent(e);
                setFlag(true);
              }}
            />
          </Form.Item>
        </div> */}
      {/* </div> */}

      <div className="row d-flex mb-30">
        <div className="col-12 col-xl-12 flex-stretch form-styling">
          <Form.Item>
            <Form.Item
              name="productsku"
              label="Product SKU"
              labelCol={{ span: 24 }}
              className="d-flex flex-column sku-field pos-index mb-0"
            >
              <AsyncProduct
                getSku={getSku}
                sku={sku}
                referralPercent={referralPercent}
                influencer_percent={influencer_percent}
              />
              {skuError && (
                <div role="alert" className="ant-form-item-explain-error">
                  Please Select Product SKU
                </div>
              )}
            </Form.Item>
          </Form.Item>

          <div className="row d-flex mb-20 product-listing-table-main">
            <div className="col">
              {sku?.length > 0 && (
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  //  rowSelection={{ ...rowSelection }}
                  dataSource={[...sku]}
                  scroll={sku.length > 4 ? { y: 350, x: 1000 } : { x: 1000 }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              onClick={() => gotoStep(current - 1)}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button>
          </div>

          <Modal
            maskClosable={false}
            className="modal-generic modal-500"
            centered
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              // <Button key="back" onClick={handleCancel}>
              //   Cancel
              // </Button>,
              <Button
                key="submit"
                type="primary"
                className="d-table m-auto"
                // onClick={handleOk}
                onClick={() => history("/external-events")}
              >
                Go to Event
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Your event is ready!</h2>
            <div className="preview-content-main-modal">
              <h3 className="preview-title">
                {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
              </h3>
              <div className="preview-date">
                <span className="text-uppercase">
                  {" "}
                  {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                </span>
                <br></br>
                {dateTime === ""
                  ? "11:40 AM"
                  : moment(dateTime).format("hh:mm A")}
              </div>
              <div className="preview-txt p-2">
                The live event should start soon !
              </div>
            </div>
          </Modal>

          <div className="d-flex ">
            <div className="d-flex">
              <Button
                // disabled
                className="default-btn outline d-flex align-items-center justify-content-center res-btn3 span-inner-initial"
                type="primary"
                size="large"
                onClick={handleButtonClick}
              >
                Exit
              </Button>

              <Button
                // disabled
                className="default-btn d-flex align-items-center justify-content-center  res-btn2 mr-0"
                type="primary"
                size="large"
                loading={loading}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    className="event-icon"
                    icon={faCaretRight}
                  />
                }
                onClick={() => {
                  if (sku.length === 0) {
                    setSkuError(true);
                  } else {
                    submit();
                  }
                }}
              >
                {formState == "add" ? <>Create Event</> : <>Update Event</>}
              </Button>
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </Form.Item>
    </>,
  ];


  const formListEdit = [
    <>
      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Event Information</h5>
          <p>
            Make a major impact with your opening line! Give your event an
            engaging title.
            {/* Make a bold statement with your introduction line ! The event title
            will appear on your tab, your social share and the homescreen name.
            So stand out, make your entrance to the world 😃 😃{" "} */}
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Event Title"
            initialValue={
              formState === "add" ? null : form.getFieldValue().title
            }
            name="title"
            rules={[
              {
                required: true,
                message: "Please Enter Event Title",
              },
            ]}
          >
            <Input
              placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
              size="large"
              showCount
              maxLength={80}
              onChange={(e) => {
                setEventTitle(e.target.value);
                setFlag(true);
              }}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Categories</h5>
          <p>Select category from provided list.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().gen_category_id}
            label="Department"
            name="gen_category_id"
            rules={[
              {
                required: true,
                message: "Please select a department",
              },
            ]}
          >
            <Select
              className="select-style dark-field"
              placeholder="Select Department"
              size="large"
              focus={false}
              onBlur={false}
              onChange={onGenderChange}
              value={gender}
              loading={genLoad}
              disabled={genLoad}
            >
              {savedGeneralCategories?.message?.map((option) => {
                return (
                  <Option value={option?.category_id}>{option?.name}</Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().category_id}
            label="Category"
            name="category_id"
            rules={[
              {
                // required: true,
                message: "Please select a category",
              },
            ]}
          >
            <Select
              placeholder="Select Category"
              size="large"
              disabled={categoryOptions?.length ? false : true}
              className="select-style dark-field"
              onChange={onCategoryChange}
              loading={catLoad}
              value={category ? category : undefined}
              defaultValue={"all"}
            >
              <Option value="all">All</Option>
              {categoryOptions?.map((option) => {
                return (
                  <Option value={option?.category_id}>
                    {option?.gen_category?.name} {" > "}
                    {option?.category_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            labelCol={{ span: 24 }}
            initialValue={form.getFieldValue().sub_category_id}
            label="Sub Category"
            name="sub_category_id"
            rules={[
              {
                // required: true,
                message: "Please select a sub category",
              },
            ]}
          >
            <Select
              placeholder="Sub Category"
              size="large"
              disabled={subCategoryOptions?.length ? false : true}
              className="select-style dark-field"
              onChange={onSubCategoryChange}
              loading={catSubLoad}
              value={subCategory ? subCategory : undefined}
              defaultValue={"all"}
            >
              <Option value="all">All</Option>
              {subCategoryOptions?.map((option) => {
                return (
                  <Option value={option?.sub_category_id}>
                    {option?.gen_category?.name} {" > "}
                    {option?.category?.category_name} {" > "}
                    {option?.sub_category_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column flex-stretch">
          <h5 className="rounded">Details</h5>
          <p>
            This information will be visible to your audience in ‘Details’ tab
            when event is Live.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Details"
            initialValue={
              formState === "add" ? null : form.getFieldValue().notes
            }
            name="notes"
          >
            <TextArea
              showCount
              maxLength={200}
              // onChange={onChange}
              className="dark-field"
              // placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
              placeholder="Enter your details"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => {
                setEventNote(e.target.value);
                setFlag(true);
              }}
            />
          </Form.Item>
        </div>
      </div>

      {/* <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Stream Type</h5>
          <p>Select stream type: Manual or Auto</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Form.Item name="streamType" labelCol={{ span: 24 }} label="Stream Type">
            <Radio.Group
              className="streaming-checkbox"
              defaultValue="manual"
              options={[
                {
                  label: 'Manual',
                  value: 'manual',
                },
                {
                  label: 'Auto',
                  value: 'auto',
                  disabled: true
                }
              ]}
              onChange={(value) => setStreamType(value.target.value)}
              value={streamType}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </div>

      </div> */}

      {
        // streamType == 'auto' &&
        // <div className="row d-flex mb-20">
        //   <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
        //     <h5 className="rounded">Date and Time</h5>
        //     <p>Set the date and time of the event.</p>
        //   </div>
        //   <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
        //     <Form.Item
        //       name="datetime"
        //       initialValue={
        //         formState === "add" ? null : form.getFieldValue().datetime
        //       }
        //       rules={[
        //         {
        //           required: true,
        //           message: "Please select date & time",
        //         },
        //         {
        //           validator: async (_, datetime) => {
        //             var travelTime = moment().add(30, "minutes");

        //             var diffTime = travelTime.diff(moment(datetime));
        //             if (diffTime > 30) {
        //               return Promise.reject(
        //                 new Error("Schedule the event after 30 minutes")
        //               );
        //             } else {
        //               return Promise.resolve();
        //             }
        //           },
        //         },
        //       ]}
        //       labelCol={{ span: 24 }}
        //       label="Date and Time"
        //     >
        //       <DatePicker
        //         style={{
        //           width: "100%",
        //         }}
        //         showTime={{ use12Hours: true, format: "HH:mm" }}
        //         onChange={(e) => {
        //           onDateTimeChange(e);
        //           setFlag(true);
        //         }}
        //         disabledDate={startDateLimit}
        //         format="MM-DD-YYYY h:mm A"
        //       />
        //     </Form.Item>
        //   </div>
        // </div>
        <div className="row d-flex mb-20">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Date and Time</h5>
            <p>Set the date and time of the event.</p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai row">
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="date"
                initialValue={formState === 'add' ? dateOnly : form.getFieldValue('datetime')}
                rules={[
                  {
                    required: true,
                    message: 'Please select date',
                  },
                ]}
                labelCol={{ span: 24 }}
                label="Date"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    onDateTimeChange2(e, 'date');
                    setFlag(true);
                  }}
                  disabledDate={startDateLimit}
                  format="MM-DD-YYYY"
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="time"
                initialValue={formState === 'add' ? timeOnly : form.getFieldValue('datetime')}
                rules={[
                  {
                    required: true,
                    message: 'Please select time',
                  },
                  {
                    validator: checkDateAndTime
                  }
                  // {
                  //   validator: async (_, datetime) => {
                  //     console.log(dateTime, 'dateTime');

                  //     var travelTime = moment().add(30, "minutes");
                  //     var diffTime = travelTime.diff(moment(datetime), 'minutes');
                  //     if (diffTime <= 30) {
                  //       return Promise.reject(
                  //         new Error("Schedule the event after 30 minutes")
                  //       );
                  //     } else {
                  //       return Promise.resolve();
                  //     }
                  //   },
                  // },
                ]}
                labelCol={{ span: 24 }}
                label="Time"
              >
                <TimePicker
                  style={{ width: '100%' }}
                  use12Hours
                  showTime={{
                    use12Hours: true, format: "HH:mm", minuteStep: 30,
                  }}
                  format="h:mm A"
                  onChange={(e) => {
                    onDateTimeChange2(e, 'time');
                    setFlag(true);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      }

      {/* <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Platform</h5>
          <p>Set the platform to which the event will be published.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Checkbox checked={platform} onChange={changePlatform}>
            Facebook
          </Checkbox>
        </div>
      </div> */}

      {/* <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Streaming Format</h5>
          <p>
            Determine the format for your live stream whether you'll have one
            primary host or multiple hosts.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Checkbox
            disabled={formState === "edit"}
            checked={!multipleHosts}
            onChange={(e) => handleMultipleHosts(!e.target.checked)}
          >
            Single
          </Checkbox>
          <Checkbox
            disabled={formState === "edit"}
            checked={multipleHosts}
            onChange={(e) => handleMultipleHosts(e.target.checked)}
          >
            Multiple
          </Checkbox>
          {formState === "edit" && (
            <p className="mt-3 text-danger">Can not be changed.</p>
          )}
        </div>
      </div> */}

      {
        streamUrl == "external" &&
        (
          <div className="row d-flex mb-20">
            <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
              <h5 className="rounded">External Stream</h5>
              <p>Put Url to show Stream on Live Event</p>
            </div>
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">

              <Form.Item
                name="ExternalStreamOrientation"
                labelCol={{ span: 24 }}
                label="External Stream Orientation"
              >
                <Radio.Group
                  onChange={(value) => setStreamUrlOrientation(value.target.value)}
                  value={streamUrlOrientation}
                  defaultValue="landscape"
                  options={[
                    {
                      label: 'Landscape',
                      value: 'landscape',
                    },
                    {
                      label: 'Portrait',
                      value: 'portrait',
                    }
                  ]}
                >
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="Externalstream"
                labelCol={{ span: 24 }}
                label="External Stream"
                rules={[
                  {
                    required: streamUrl == "external" ? true : false,
                    message: "Please enter external stream url",
                  },
                  {
                    pattern: /^(https?:\/\/)?(www\.)?([\da-z.-]+)?(\.[a-z.]{2,6})?(\/[\w .-]*)*\/?.*\.m3u8$/,
                    message: 'Enter a valid .m3u8 URL!',
                  }
                ]}
              >
                <Input
                  placeholder="Enter External Url"
                  size="large"
                // defaultValue={validate?.payload?.message?.name}
                />
              </Form.Item>
            </div>
          </div>
        )}
      {streamUrl === "local" && (
        <div className="row d-flex mb-30">
          <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
            <h5 className="rounded">Assign Host</h5>
            <p>
              Specify event Host. Please note these Hosts’ event addresses must
              be registered on ormelive.com as a Shopper or an Influencer.
            </p>
          </div>
          <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
            <div className="row">
              <div className="col-md-6 col-xl-6">
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Brand"
                  name="producer"
                >
                  <Input
                    placeholder="Producer"
                    size="large"
                    disabled
                    defaultValue={validate?.payload?.message?.name}
                  />
                </Form.Item>
              </div>
              <div className="col-md-6 col-xl-6">
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Producer"
                  name="producer"
                >
                  <Input
                    placeholder="Producer"
                    size="large"
                    disabled
                    defaultValue={validate?.payload?.message?.email}
                  />
                </Form.Item>
              </div>
            </div>
            <div className={`${multipleHosts && "row"}`}>
              <div className={`${multipleHosts && "col-md-6 col-xl-6"}`}>
                <Form.Item
                  name="primary"
                  label="Primary Host"
                  labelCol={{ span: 24 }}
                  className="field-style2"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select Primary Host",
                //   },
                // ]}
                >
                  {host?.length > 0 && formState == "edit" ? (
                    <>
                      <AsyncHost
                        getHostPrimary={getHostPrimary}
                        host={host}
                        host2={host2}
                        host3={host3}
                        host4={host4}
                        form={formState}
                        flag={setFlag}
                      />
                      {hostError && (
                        <div
                          role="alert"
                          className="ant-form-item-explain-error"
                        >
                          Please select a host
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <AsyncHost
                        getHostPrimary={getHostPrimary}
                        host={host}
                        host2={host2}
                        host3={host3}
                        host4={host4}
                        form={formState}
                      />
                      {hostError && (
                        <div
                          role="alert"
                          className="ant-form-item-explain-error"
                        >
                          Enter a host Email
                        </div>
                      )}
                    </>
                  )}
                </Form.Item>
              </div>
              {multipleHosts && (
                <>
                  <div className="col-md-6 col-xl-6">
                    <Form.Item
                      name="host2"
                      label="Host 2"
                      labelCol={{ span: 24 }}
                      className="field-style2"
                    // className="d-flex flex-column sku-field"
                    >
                      {host2?.length > 0 && formState == "edit" ? (
                        <>
                          <AsyncHost2
                            getHost2={getHost2}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                            flag={setFlag}
                          />
                          {hostError2 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Please select a host
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <AsyncHost2
                            getHost2={getHost2}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                          />
                          {hostError2 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Enter a host Email
                            </div>
                          )}
                        </>
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <Form.Item
                      name="host3"
                      label="Host 3"
                      labelCol={{ span: 24 }}
                      className="field-style2"
                    // className="d-flex flex-column sku-field"
                    >
                      {host3?.length > 0 && formState == "edit" ? (
                        <>
                          <AsyncHost3
                            getHost3={getHost3}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                            flag={setFlag}
                          />
                          {hostError3 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Please select a host
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <AsyncHost3
                            getHost3={getHost3}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                          />
                          {hostError3 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Enter a host Email
                            </div>
                          )}
                        </>
                      )}
                    </Form.Item>
                  </div>
                  <div className="col-md-6 col-xl-6">
                    <Form.Item
                      name="host4"
                      label="Host 4"
                      labelCol={{ span: 24 }}
                      className="field-style2"
                    // className="d-flex flex-column sku-field"
                    >
                      {host4?.length > 0 && formState == "edit" ? (
                        <>
                          <AsyncHost4
                            getHost4={getHost4}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                            flag={setFlag}
                          />
                          {hostError4 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Please select a host
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <AsyncHost4
                            getHost4={getHost4}
                            host={host}
                            host2={host2}
                            host3={host3}
                            host4={host4}
                            form={formState}
                          />
                          {hostError4 && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Enter a host Email
                            </div>
                          )}
                        </>
                      )}
                    </Form.Item>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
        </div>
      )}

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="#" className="prev-next-link ff-nunito">
            {/* <FontAwesomeIcon icon={faAngleLeft} />
            <span onClick={() => prevStep()}> Previous</span> */}
          </Link>

          <div className="d-flex">
            <Button
              // disabled
              className="default-btn outline d-flex align-items-center justify-content-center res-btn3 span-inner-initial"
              type="primary"
              size="large"
              // onClick={() => gotoStep(current + 1)}
              onClick={handleButtonClick}
            >
              Exit
            </Button>
            <Button
              // disabled
              className="default-btn d-flex align-items-center justify-content-center res-btn2 mr-0"
              type="primary"
              size="large"
              icon={
                <FontAwesomeIcon
                  size="sm"
                  className="event-icon"
                  icon={faCaretRight}
                />
              }
              // onClick={() => gotoStep(current + 1)}
              onClick={() => {
                if (thankyou) {
                  setActiveKey("3")
                }
                if (host?.length === 0) {
                  if (streamUrl === "external") {
                    gotoStep(current + 1);
                  }
                  setHostError(true);
                } else {
                  gotoStep(current + 1);
                }
              }}
            >
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </>,

    <>
      <div className="row d-flex mb-20">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Pre LIVE</h5>
          <p>Upload event banner and let your audience identify your brand.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <h5>Banner</h5>
          <p className="color-light">1280px x 720px (jpg or png format)</p>
          {!sampleBanner && (
            <>
              {banner && typeof banner !== "object" && !previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={banner}
                  />
                </Form.Item>
              )}
              {typeof banner === "object" && previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={previewImage}
                  />
                </Form.Item>
              )}
              {banner == previewImage && banner && previewImage && (
                <Form.Item className="preview-image-main" name="banner">
                  <Image
                    // width={100}
                    // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                    // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                    src={previewImage}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Form.Item
                  name="banner"
                  valuePropName={formState !== "edit" && "fileList"}
                  //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                  //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                  getValueFromEvent={normFile}
                  noStyle
                  multiple={false}
                  rules={[
                    {
                      required: true,
                      message: "Please upload banner image",
                    },
                  ]}
                >
                  <Upload.Dragger
                    customRequest={dummyRequest}
                    className={`upload-image-main ${banner?.length !== 0 &&
                      banner !== undefined &&
                      previewImage &&
                      "d-none"
                      }`}
                    {...uploadProps}
                    name="banner"
                    // defaultFileList={
                    //   formState === "edit" &&
                    //   form.getFieldValue()?.banner === null
                    //     ? null
                    //     : [
                    //         {
                    //           url: form.getFieldValue()?.banner,
                    //         },
                    //       ]
                    // }
                    defaultFileList={
                      formState === "edit"
                        ? sampleBanner || form.getFieldValue()?.banner === null
                          ? form.getFieldValue()?.banner
                          : form.getFieldValue()?.banner?.length === 1
                            ? form.getFieldValue()?.banner
                            : [
                              {
                                url: form.getFieldValue()?.banner,
                              },
                            ]
                        : null
                    }
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                    <p className="ant-upload-text">drag files here to upload</p>
                    {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p> */}
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </>
          )}
          {sampleBanner && (
            <Form.Item className="preview-image-main" name="banner">
              <Image
                // width={100}
                // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                src={previewImage}
              />
            </Form.Item>
          )}
          <Checkbox checked={sampleBanner} onChange={changeSampleBanner}>
            Use a sample image
          </Checkbox>
        </div>
      </div>
      <div className="row d-flex mb-30">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Post LIVE</h5>
          <p>
            Thank everyone for attending the event and bring them back to your
            store in future!
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Thank you message"
            className="mb-40"
            initialValue={
              formState === "add" ? null : form.getFieldValue().thankyou_message
            }
            name="thankyou_message"
          >
            <TextArea
              showCount
              maxLength={250}
              // onChange={onChange}
              className="dark-field"
              placeholder="The live is now over. Thank you for coming to our show."
              autoSize={{ minRows: 4, maxRows: 6 }}
              onFocus={() => setActiveKey("3")}
              onChange={(e) => {
                setEventThankyou(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </div>

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              onClick={() => { gotoStep(current - 1); setActiveKey("1") }}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button>
          </div>

          <Modal
            maskClosable={false}
            className="modal-generic modal-500"
            centered
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              // <Button key="back" onClick={handleCancel}>
              //   Cancel
              // </Button>,
              <Button
                key="submit"
                type="primary"
                className="d-table m-auto"
                // onClick={handleOk}
                onClick={() => history("/external-events")}
              >
                Go to Event
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Your event is ready!</h2>
            <div className="preview-content-main-modal">
              <h3 className="preview-title">
                {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
              </h3>
              <div className="preview-date">
                <span className="text-uppercase">
                  {" "}
                  {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                </span>
                <br></br>
                {dateTime === ""
                  ? "11:40 AM"
                  : moment(dateTime).format("hh:mm A")}
              </div>
              <div className="preview-txt p-2">
                The live event should start soon !
              </div>
            </div>
          </Modal>

          <div className="d-flex ">
            <div className="d-flex">
              <Button
                // disabled
                className="default-btn outline d-flex align-items-center justify-content-center res-btn3 span-inner-initial"
                type="primary"
                size="large"
                onClick={handleButtonClick}
              >
                Exit
              </Button>

              <Button
                // disabled
                className="default-btn d-flex align-items-center justify-content-center  res-btn2 mr-0"
                type="primary"
                size="large"
                loading={loading}
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    className="event-icon"
                    icon={faCaretRight}
                  />
                }
                onClick={() => {
                  if (sku.length === 0) {
                    setSkuError(true);
                    notification.error({
                      message: "Please Add Product",
                      className: "toast-error",
                    });
                  } else {
                    submit();
                  }
                }}
              >
                {formState == "add" ? <>Create Event</> : <>Update Event</>}
              </Button>
            </div>
            {/* </Spin> */}
          </div>
        </div>
      </Form.Item>
    </>,
  ];

  const formListProduct = [
    load ? (
      <SkeletonComponent />
    ) : (
      <>
        {/* <div className="row">
          <div className="col-md-6">
            <label className="pb-0">Promo Code</label>
            <Select
              size="small"
              filterOption={(input, options) =>
                options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={
                productPromoCodePromo
                  ? productPromoCodePromo
                  : "Select PromoCode"
              }
              //disabled={!(formState === "add" || formState === "edit")}
              placeholder="Select PromoCode"
              //loading={this.state.promoCond}
              optionFilterProp="children"
              className="w-100 select-modal mb-20"
              // onSearch={onSearch}
              onChange={(options, e) => changePromoCode(e, options)}
              showSearch
              allowClear={false}
              loading={promoRequest?.loading ? true : false}
              disabled={promoRequest?.loading ? true : false}
            >
              {promoRequest?.message?.map((customer, key) => {
                return (
                  <Option key={customer.promo_percent + " " + key}>
                    {customer.promo}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="col-md-6">
            <label>Discount</label>
            <div className="promo_discount form-control mb-20">
              {productPromoCodeDscs}
            </div>
          </div> */}

        {/* <div className="col-md-3">
            <label>Cashback</label>
            <Form.Item
              labelCol={{ span: 24 }}
              // label="Influencer Fee"
              className="mb-20 label-padb-0"
              // initialValue={
              //   formState === "add"
              //     ? null
              //     : form.getFieldValue().influencer_percent
              // }
              initialValue={
                getStructureFees?.payload?.message?.fee_structure?.shopper_cashback ? getStructureFees?.payload?.message?.fee_structure?.shopper_cashback : 0
              }
              name="influencer_percent"
            >
              <InputNumber
                disabled
                className="w-100 select-modal"
                defaultValue={0}
                size="large"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                onChange={(e) => {
                  setFlag(true);
                  setInfluencer_percent(e);
                }}
              />
            </Form.Item>
          </div>
          <div className="col-md-3">
            <label>Referral Fee</label>
            <Form.Item
              labelCol={{ span: 24 }}
              // label="Referral Fee"
              className="mb-20 label-padb-0 w-100"
              // initialValue={
              //   formState === "add" ? null : form.getFieldValue().referral_percent
              // }
              initialValue={
                getStructureFees?.payload?.message?.fee_structure?.referral_fee ? getStructureFees?.payload?.message?.fee_structure?.referral_fee : 0
              }
              name="referral_percent"
            >
              <InputNumber
                disabled
                className="w-100 select-modal"
                defaultValue={0}
                size="large"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                onChange={(e) => {
                  setReferralPercent(e);
                  setFlag(true);
                }}
              />
            </Form.Item>
          </div> */}
        {/* </div> */}

        <div className="row d-flex mb-30">
          <div className="col-12 col-xl-12 flex-stretch form-styling">
            <Form.Item>
              <Form.Item
                name="productsku"
                label="Product SKU"
                labelCol={{ span: 24 }}
                className="d-flex flex-column sku-field pos-index mb-0"
              >
                <AsyncProduct
                  getSku={getSku}
                  sku={sku}
                  referralPercent={referralPercent}
                  influencer_percent={influencer_percent}
                />
                {skuError && (
                  <div role="alert" className="ant-form-item-explain-error">
                    Please Select Product SKU
                  </div>
                )}
              </Form.Item>
            </Form.Item>

            <div className="row d-flex mb-20 product-listing-table-main">
              <div className="col">
                {sku?.length > 0 && (
                  <Table
                    pagination={false}
                    columns={columns}
                    size="small"
                    className="product-listing-table"
                    //  rowSelection={{ ...rowSelection }}
                    dataSource={[...sku]}
                    scroll={sku.length > 4 ? { y: 350, x: 1000 } : { x: 1000 }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Form.Item>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {/* <button
              onClick={() => gotoStep(current - 1)}
              className="border-0 bg-transparent prev-next-link ff-nunito"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </button> */}
            </div>

            <Modal
              maskClosable={false}
              className="modal-generic modal-500"
              centered
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              closeIcon={<FontAwesomeIcon icon={faXmark} />}
              footer={[
                // <Button key="back" onClick={handleCancel}>
                //   Cancel
                // </Button>,
                <Button
                  key="submit"
                  type="primary"
                  className="d-table m-auto"
                  // onClick={handleOk}
                  onClick={() => history("/external-events")}
                >
                  Go to Event
                </Button>,
              ]}
            >
              <h2 className="modal-hd1">Your event is ready!</h2>
              <div className="preview-content-main-modal">
                <h3 className="preview-title">
                  {eventTitle === "" ? "[Preview Event Title]" : eventTitle}
                </h3>
                <div className="preview-date">
                  <span className="text-uppercase">
                    {" "}
                    {dateTime === "" ? "Today" : dateToFromNowDaily(dateTime)}
                  </span>
                  <br></br>
                  {dateTime === ""
                    ? "11:40 AM"
                    : moment(dateTime).format("hh:mm A")}
                </div>
                <div className="preview-txt p-2">
                  The live event should start soon !
                </div>
              </div>
            </Modal>

            <div className="d-flex ">
              <div className="d-flex">
                <Button
                  // disabled
                  className="default-btn outline d-flex align-items-center justify-content-center res-btn3 span-inner-initial"
                  type="primary"
                  size="large"
                  onClick={handleButtonClick}
                >
                  Exit
                </Button>

                <Button
                  // disabled
                  className="default-btn d-flex align-items-center justify-content-center  res-btn2 mr-0"
                  type="primary"
                  size="large"
                  loading={loading}
                  icon={
                    <FontAwesomeIcon
                      size="sm"
                      className="event-icon"
                      icon={faCaretRight}
                    />
                  }
                  onClick={() => {
                    if (sku.length === 0) {
                      setSkuError(true);
                    } else {
                      submit();
                    }
                  }}
                >
                  {formState == "add" ? <>Create Event</> : <>Update Event</>}
                </Button>
              </div>
              {/* </Spin> */}
            </div>
          </div>
        </Form.Item>
      </>
    ),
  ];

  const handleDateTimeChange = (date, time) => {
    if (date && time) {
      const combinedDateTime = moment(date).set({
        hour: moment(time).hour(),
        minute: moment(time).minute(),
      });

      onDateTimeChange(combinedDateTime);
      setFlag(true);
    }
  };


  if (genLoad) {
    return (
      <>
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      </>
    );
  }
  const handlePage = (link, menu) => {
    history(link, {
      state: { key: link.split("/").length > 2 ? link.split("/")[2] : link.split("/")[1], openKey: menu }
    }
    );
  }

  return (
    <>
      <div className="create-event-top-area mb-4 d-flex justify-content-center">
        <div className="top-area-box ">
          <div className="title-area mb-0 pb-0">
            <h1 className="mb-0 pb-0">
              {" "}
              {formState == "add" ? <>Schedule Event</> : <>Update Event</>}
            </h1>
          </div>
        </div>
      </div>

      {!savedGeneralCategories?.message?.length ? (
        <div className="container-fluid">
          <div class="coming_iner">
            <h4 className="mb-4">Setup Category To Schedule Event</h4>
            <div className="buttons-area">
              <Button
                onClick={() => handlePage("/account/categories", "settings")}
                className="default-btn medium-btn fltr-hpr fw-normal"
              >
                Setup Category
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {
            getInventoryProductsBySkuAndTitle?.message?.length === 0 ?
              <div className="container-fluid">
                <div class="coming_iner">
                  <h4 className="mb-4">Activate Inventory To Schedule Event</h4>
                  <div className="buttons-area">
                    <Button
                      onClick={() => handlePage("/inventoryy/active", "inventory")}
                      className="default-btn medium-btn fltr-hpr fw-normal"
                    >
                      Manage Inventory
                    </Button>
                  </div>
                </div>
              </div>
              :
              !validate?.payload?.message?.salesforce && !validate?.payload?.message?.shopify && !validate?.payload?.message?.magento && !validate?.payload?.message?.scoc && !validate?.payload?.message?.seller_center && !validate?.payload?.message?.squarespace ?
                <div className="container-fluid">
                  <div class="coming_iner">
                    <h4 className="mb-4">Setup Ecommerce To Schedule Event</h4>
                    <div className="buttons-area">
                      <Button
                        className="default-btn medium-btn fltr-hpr fw-normal"
                        onClick={() => handlePage("/account/ecommerce", "settings")}
                      >
                        Ecommerce Setup
                      </Button>
                    </div>
                  </div>
                </div>
                :
                <div className="create-event-wrapper  mb-30">
                  <div
                    className={
                      current != 2
                        ? "create-event-box-left"
                        : "create-event-box-left full-width"
                    }
                  >
                    {searchParams.get("page") === null && (
                      <Steps
                        {...stepsProps}
                        className="event-stepss"
                        responsive={false}
                      >
                        <Step
                          title={
                            <>
                              <img className="step-img" src={step1} alt="step1" />
                              <div className="setup-hd">Setup</div>
                            </>
                          }
                        />
                        <Step
                          title={
                            <>
                              <img className="step-img" src={step2} alt="step2" />
                              <div className="setup-hd">Design</div>
                            </>
                          }
                        />
                        <Step
                          title={
                            <>
                              <img className="step-img" src={step3} alt="step3" />
                              <div className="setup-hd">Products</div>
                            </>
                          }
                        />
                      </Steps>
                    )}

                    {searchParams.get("page") === null && (
                      <Form {...formProps}>{formList[current]}</Form>
                    )}

                    {searchParams.get("page") === "edit" && (
                      <Form {...formProps}>{formListEdit[current]}</Form>
                    )}

                    {searchParams.get("page") === "product" && (
                      <Form {...formProps}>{formListProduct[current]}</Form>
                    )}
                  </div>
                  {current != 2 && (
                    <div className="create-event-box-right">
                      {matches ? (
                        <Affix offsetTop={top}>
                          <div>
                            <h2 className="preview-hd">Preview</h2>
                            <Tabs
                              activeKey={activeKey}
                              onChange={(k) => setActiveKey(k)}
                              centered
                              size="medium"
                              className="preview-tabs-main"
                            >
                              <TabPane tab="Before" key="1">
                                <div className="preview-mobile-box">
                                  <div className="preview-box">
                                    <div
                                      className={`preview-img ${sampleBanner ? "sample-image" : ""
                                        }`}
                                    >
                                      {/* <img src={previewImg} alt="Upload your banner" /> */}
                                      <img
                                        src={
                                          (previewImage === "" && !bannerUrl)
                                            ? previewImg
                                            : (bannerUrl && (!previewImage))
                                              ? bannerUrl
                                              : previewImage
                                        }
                                        alt="Upload your banner"
                                      />
                                    </div>
                                    <div className="preview-content-main">
                                      <h3 className="preview-title">
                                        {eventTitle === ""
                                          ? "[Preview Event Title]"
                                          : eventTitle}
                                      </h3>
                                      <div className="preview-date">
                                        <span className="text-uppercase">
                                          {" "}
                                          {dateTime === ""
                                            ? "Today"
                                            : dateToFromNowDaily(dateTime)}
                                        </span>

                                        <br></br>
                                        <span>{moment(dateTime).format("MM-DD-YYYY")} </span>
                                        {dateTime === ""
                                          ? "11:40 AM"
                                          : moment(dateTime).format("hh:mm A")}
                                      </div>
                                      <div className="preview-txt p-2">
                                        The live event should start soon !
                                      </div>

                                      <div className="buttons-main mt-4 mb-4">
                                        {/* <Button
                              className="default-btn color-secondary ml-0 mr-0 text-uppercase d-flex align-items-center justify-content-center mb-3"
                              block
                              type="primary"
                              // size="medium"
                              icon={
                                <FontAwesomeIcon
                                  size="sm"
                                  className="event-icon"
                                  icon={faCalendarPlus}
                                />
                              }
                            >
                              Remind Me
                            </Button>

                            <Button
                              className="default-btn ml-0 mr-0 light text-uppercase d-flex align-items-center justify-content-center"
                              block
                              type="primary"
                              // size="medium"
                              icon={
                                <FontAwesomeIcon
                                  size="sm"
                                  className="event-icon"
                                  icon={faShare}
                                />
                              }
                            >
                              Share Event
                            </Button> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPane>

                              {/* <TabPane tab="Live" key="2">
                    <div className="preview-mobile-box">Live Preview</div>
                  </TabPane> */}
                              <TabPane tab="After" key="3">
                                <div className="preview-mobile-box">
                                  <div className="preview-box">
                                    {previewImage && (
                                      <div
                                        className={`preview-img ${sampleBanner ? "sample-image" : ""
                                          }`}
                                      >
                                        <img src={previewImage} alt="preview Image" />
                                      </div>
                                    )}
                                    {!previewImage && (
                                      <div
                                        className={`preview-img ${sampleBanner ? "sample-image" : ""
                                          }`}
                                      >
                                        <img src={
                                          // previewImg
                                          (previewImage === "" && !bannerUrl)
                                            ? previewImg
                                            : (bannerUrl && (!previewImage))
                                              ? bannerUrl
                                              : previewImage
                                        } alt="preview Image" />
                                      </div>
                                    )}
                                    <div className="preview-content-main">
                                      <h3 className="preview-title">{eventTitle}</h3>
                                    </div>
                                    <div className="preview-content-main">
                                      <h3 className="preview-title">
                                        {thankyou === ""
                                          ? "The live is now over. Thank you for coming to our show."
                                          : thankyou}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </TabPane>
                            </Tabs>
                          </div>
                        </Affix>
                      ) : (
                        <div>
                          <h2 className="preview-hd">Preview</h2>
                          <Tabs
                            centered
                            size="medium"
                            activeKey={activeKey}
                            onChange={(k) => setActiveKey(k)}
                            className="preview-tabs-main"
                          >
                            <TabPane tab="Before" key="1">
                              <div className="preview-mobile-box">
                                <div className="preview-box">
                                  <div
                                    className={`preview-img ${sampleBanner ? "sample-image" : ""
                                      }`}
                                  >
                                    {/* <img src={previewImg} alt="Upload your banner" /> */}
                                    <img
                                      src={
                                        // previewImage === ""
                                        //   ? previewImg
                                        //   : previewImage
                                        (previewImage === "" && !bannerUrl)
                                          ? previewImg
                                          : (bannerUrl && (!previewImage))
                                            ? bannerUrl
                                            : previewImage
                                      }
                                      alt="Upload your banner"
                                    />
                                  </div>
                                  <div className="preview-content-main">
                                    <h3 className="preview-title">
                                      {eventTitle === ""
                                        ? "[Preview Event Title]"
                                        : eventTitle}
                                    </h3>
                                    <div className="preview-date">
                                      <span className="text-uppercase">
                                        {" "}
                                        {dateTime === ""
                                          ? "Today"
                                          : dateToFromNowDaily(dateTime)}
                                      </span>
                                      <br></br>
                                      {dateTime === ""
                                        ? "11:40 AM"
                                        : moment(dateTime).format("hh:mm A")}
                                    </div>
                                    <div className="preview-txt p-2">
                                      The live event should start soon !
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPane>

                            <TabPane tab="After" key="3">
                              <div className="preview-mobile-box">
                                <div className="preview-box">
                                  {previewImage && (
                                    <div
                                      className={`preview-img ${sampleBanner ? "sample-image" : ""
                                        }`}
                                    >
                                      <img src={previewImage} alt="preview Image" />
                                    </div>
                                  )}
                                  {!previewImage && (
                                    <div
                                      className={`preview-img ${sampleBanner ? "sample-image" : ""
                                        }`}
                                    >
                                      <img src={previewImg} alt="preview Image" />
                                    </div>
                                  )}
                                  <div className="preview-content-main">
                                    <h3 className="preview-title">{eventTitle}</h3>
                                  </div>
                                  <div className="preview-content-main">
                                    <h3 className="preview-title">
                                      {" "}
                                      {thankyou === ""
                                        ? "The live is now over. Thank you for coming to our show."
                                        : thankyou}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </TabPane>
                          </Tabs>
                        </div>
                      )}
                    </div>
                  )}
                </div>
          }
        </>
      )}

      <Modal
        className="modal-generic modal-500 form-styling"
        centered
        visible={isCheckedOpen}
        onOk={OpenSubmit}
        onCancel={handleCancelInfo}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        maskClosable={false}
        keyboard={false}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            // className="d-table m-auto"
            onClick={handleOkInfo}
          >
            Save
          </Button>,
          // <Button key="back" onClick={handleCancelInfo}>
          //   Exit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Add Info</h2>
        {/* <p>Please note these email addresses must be registered on ormelive.com as a Shopper.
            By submitting this, the role will have an access to control room chat.
          </p> */}
        <h5 className="rounded">Title</h5>
        <Input
          placeholder="Enter Title"
          className="mb-20"
          value={infoTitle}
          label="Title"
          size="large"
          onChange={onChangeTitle}
        />
        <h5 className="rounded">Description</h5>
        <TextArea
          value={infoDesc}
          label="Description"
          size="large"
          placeholder="Description....."
          onChange={onChangeDescription}
        />
      </Modal>

      <Modal
        maskClosable={false}
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isModalOpen}
        onOk={handleOk}
        //onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={""} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={() => history("/external-events")}
          >
            Go to Event
          </Button>,
        ]}
      >
        {formState == "edit" ? (
          <h2 className="modal-hd1">Event has been updated</h2>
        ) : (
          <h2 className="modal-hd1">Event has been created</h2>
        )}
        <div className="row">
          <div className="col-lg-4">
            <h5 className="border-d">Banner</h5>
            {previewImage && (
              <div
                className={`preview-img ${sampleBanner ? "sample-image" : ""}`}
              >
                <img src={previewImage} alt="preview Image 11" />
              </div>
            )}

            {!previewImage && (
              <div
                className={`preview-img ${sampleBanner ? "sample-image" : ""}`}
              >
                <img
                  src={
                    banner?.type ? URL.createObjectURL(banner) : previewImage
                  }
                  alt="preview Image 22"
                />
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6 mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Event</h4>
                  <h5 className="preview-date">{eventTitle}</h5>
                  <div className="event-detail-txt">
                    <CalendarFilled /> LIVE-SHOPPING-EVENT
                  </div>
                </div>
              </div>
              <div className="col-md-6  mb-20">
                <div class="preview-date-main">
                  <h4 class="date-txt">Date</h4>
                  <h5 class="preview-date">
                    {" "}
                    {moment(dateTime).utc().format("MM-DD-YYYY")}
                  </h5>
                  <h6 class="preview-time">
                    At {moment(dateTime).format("hh:mm:ss A")}
                  </h6>
                </div>
              </div>
            </div>

            <div className="row flex-stretch">
              <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Department</h4>
                  <h5 className="preview-date">{form.getFieldValue().category_id_name}</h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main producer-style">
                  <h4 className="date-txt">Attendee</h4>

                  <h5 className="preview-date">
                    <FontAwesomeIcon className="" icon={faUserTie} /> Producer:{" "}
                    <span className="fw-normal">
                      {validate?.payload?.message?.name}
                    </span>
                  </h5>
                  {host?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} />{" "}
                          Primary:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host2?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host1:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host3?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host2:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host4?.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host3:{" "}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>


          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row d-flex mb-20">
              <div className="col-12">
                <h5 className="border-hd">Product List</h5>
              </div>
              <div className="col">
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  //  rowSelection={{ ...rowSelection }}
                  dataSource={sku}
                  scroll={sku?.length > 2 ? { y: 250, x: 1000 } : { x: 1000 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        zIndex={999999}
        maskClosable={false}
        className="modal-generic modal-600"
        centered
        visible={isModalOpenDetail}
        onOk={handleOkDetail}
        onCancel={handleCancelDetail}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        // footer={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancelDetail}
            className="d-table m-auto"
          >
            Exit
          </Button>,
          // <Button key="submit" type="primary"
          //   className="d-table m-auto"
          //   // onClick={handleOk}
          //   // onClick={handleCancel}
          // >
          //   Submit
          // </Button>,
        ]}
      >
        <h2 className="modal-hd1">Details</h2>
        <div className="co">
          {/* <Table
            pagination={false}
            columns={columnDetails}
            size="small"
            className="product-listing-tabl inventory-box-main details-table"
            //  rowSelection={{ ...rowSelection }}
            dataSource={dataDetails}
            // scroll={{
            //     x: 500,
            //     y: 500,
            // }}
            scroll={dataDetails?.length > 10 ? { y: 400, x: 500 } : { x: 500 }}
          /> */}
          <div className="table-container inventory-table">
            <table
              class={`table m-0 ${dataDetails?.productOpt?.length &&
                dataDetails?.productOpt?.length + 2 == 5
                ? "width-item-5"
                : ""
                } ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 6
                  ? "width-item-6"
                  : ""
                }`}
            >
              <thead>
                <tr>
                  {dataDetails?.productOpt?.map((item, i) => {
                    return (
                      <th className="width-25 border-bottom-0">{item.name}</th>
                    );
                  })}
                  <th className="width-25 border-bottom-0">Stock</th>
                  <th className="width-25 border-bottom-0">Price</th>
                </tr>
              </thead>
            </table>
            <div className="table-body scrollbar-style">
              <table
                class={`table ${dataDetails?.productOpt?.length &&
                  dataDetails?.productOpt?.length + 2 == 5
                  ? "width-item-5"
                  : ""
                  } ${dataDetails?.productOpt?.length &&
                    dataDetails?.productOpt?.length + 2 == 6
                    ? "width-item-6"
                    : ""
                  }`}
              >
                <tbody>
                  {dataDetails?.variants?.map((item, i) => {
                    return (
                      <tr>
                        <td className="width-25">{item.option1}</td>
                        {item.option2 && (
                          <td className="width-25">{item.option2}</td>
                        )}
                        {item.option3 && (
                          <td className="width-25">{item.option3}</td>
                        )}
                        <td className="width-25">{item.inventory_quantity}</td>
                        <td className="width-25">${item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        className="modal-generic modal-600"
        centered
        visible={streamModal}
        onOk={() => {
          setIsModalOpenStream(false);
        }}
        onCancel={() => {
          setIsModalOpenStream(false);
        }}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={() => setIsModalOpenStream(false)}
          >
            Save
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Stream Source</h2>
        <div className="co">
          <h5 className="rounded">Select External Stream</h5>
          <Input
            placeholder="Enter"
            className="mb-20"
            value={infoTitle}
            label="Title"
            size="large"
            onChange={onChangeTitle}
          />
        </div>
      </Modal>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ReactRouterPrompt
          // BlockerFunction={(currentLocation, nextLocation, historyAction)}
          when={
            ((host.length > 0 ||
              host2.length > 0 ||
              host3.length > 0 ||
              host4.length > 0 ||
              form.getFieldValue().title ||
              form.getFieldValue().notes ||
              form.getFieldValue().datetime) &&
              promptModal &&
              formState === "add") ||
            (formState === "edit" && flag && promptModal && true)
          }
        >
          {({ isActive, onConfirm, onCancel }) =>
            isActive && (
              <Modal
                maskClosable={false}
                className="modal-generic modal-500"
                centered
                visible={isActive}
                onOk={() => {
                  onConfirm();
                }}
                onCancel={onCancel}
                closeIcon={<FontAwesomeIcon icon={faXmark} />}
                footer={[
                  <Button type="primary" onClick={onCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    // onClick={handleOk}
                    onClick={() => {
                      onConfirm();
                    }}
                  >
                    Ok
                  </Button>,
                ]}
              >
                <h2 className="modal-hd1">Warning</h2>
                <div className="my-4">
                  <h5>By leaving this page you will lose all your changes.</h5>
                  <h5>Would you like to continue?</h5>
                </div>
              </Modal>
              // <div className="lightbox">
              //   <div className="container">
              //     <p>Do you really want to leave?</p>
              //     <button onClick={onCancel}>Cancel</button>
              //     <button onClick={onConfirm}>Ok</button>
              //   </div>
              // </div>
            )
          }
        </ReactRouterPrompt>
      </ErrorBoundary>
    </>
  );
}
